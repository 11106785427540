.page404-container {
  display: flex; 
  justify-content: center; 
  align-items: center; 
  height: 50vh; 
}

.custom-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%; /* Ensure it takes the full width */
}

.image-container {
  position: relative; /* This is necessary for positioning the text over the image */
  flex: 1; /* Allow image container to take half the space */
  display: flex;
  justify-content: center; /* Center the image */
}

.img_404 {
  width: 300px; /* Adjust as necessary */
  height: 300px; /* Adjust as necessary */
}



.text-container {
  flex: 1; /* Allow text container to take half the space */
  text-align: center; /* Align text to the left */
}

.page404-container h2 {
  font-size: 24px;
  margin: 10px 0;
}

.page404-container p {
  font-size: 16px;
  margin-bottom: 20px;
}

.home-link {
  font-size: 18px;
  color: #007bff;
  text-decoration: none;
}

.home-link:hover {
  text-decoration: underline;
}

.bg-img{
  background-image: url('../images/aboutg.jpg');
}