.baar-img{
    max-width: 600px;

}
.baar-img img{
    width: 100%;
    border-radius: 5px;
    padding-left: 15px;
    padding-bottom: 15px;
    border-left: 3px solid #cd5f2d;
    border-bottom: 3px solid #cd5f2d;

}

.bar-sec{
    padding: 70px 0px;
    background-color: #d3d3d3;
}

.second-par{
    margin-top: 10px;
    color: #000;
}

.paragraph-bar{
    color: #000;
}

paragraph-bar{
    display: flex;
    justify-content: end;
}

@media(max-width: 768px){
    .content-bar{
        margin-top: 50px;

    }
}