/*---------------------
  Footer
-----------------------*/

.footer-section {
    background: #242424;
    padding-top: 72px;
}

.footer-item {
    margin-bottom: 30px;
    padding: 0 30px;
}

.footer-item .footer-logo {
    margin-bottom: 25px;
}

.footer-item .footer-logo a {
    display: inline-block;
    width: 100px;
    height: 100px;

}

.footer-item .footer-logo a img {
    display: inline-block;
    width: 100%;
    height: 100%;
    transform: scale(1.2, 1.2);

}

.footer-item h5 {
    color: chocolate;
    font-weight: 500;
    margin-bottom: 10px;
}

.footer-item p {
    color: #fff;
    font-weight: 500;
    margin-bottom: 57px;
}

.footer-item .newslatter-form {
    position: relative;
}

.footer-item .newslatter-form input {
    width: 100%;
    height: 50px;
    background: #676767;
    border: none;
    font-size: 11px;
    color: #ffffff;
    padding-left: 25px;
    padding-right: 15px;
}

.footer-item .newslatter-form input::-webkit-input-placeholder {
    color: #ffffff;
    font-weight: 600;
    font-style: italic;
}

.footer-item .newslatter-form input::-moz-placeholder {
    color: #ffffff;
    font-weight: 600;
    font-style: italic;
}

.footer-item .newslatter-form input:-ms-input-placeholder {
    color: #ffffff;
    font-weight: 600;
    font-style: italic;
}

.footer-item .newslatter-form input::-ms-input-placeholder {
    color: #ffffff;
    font-weight: 600;
    font-style: italic;
}

.footer-item .newslatter-form input::placeholder {
    color: #ffffff;
    font-weight: 600;
    font-style: italic;
}

.footer-item .newslatter-form button {
    font-size: 14px;
    color: #ffffff;
    background: #F9AD81;
    display: inline-block;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    padding: 14px 25px;
    height: 100%;
    font-weight: 500;
    cursor: pointer;
}

.footer-item ul {
    margin: 0;
    padding: 0;
}

.footer-item ul li {
    list-style: none;
    color: #fff;
    line-height: 24px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    position: relative;
    /* padding-left: 25px; */
    margin-bottom: 11px;
}

.footer-item ul li i {
    color: chocolate;
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
}

.copyright {
    background: #171717;
    overflow: hidden;
    padding: 24px;
    margin-top: 30px;
}

.copyright .copyright-text {
    float: left;
    font-size: 13px;
    color: #fff;
    font-weight: 600;
    line-height: 28px;
}

.copyright .copyright-text a {
    color: #9d9d9d;
}

.copyright .copyright-text a:hover {
    color: chocolate;
}

.copyright ul {
    float: right;
}

.copyright ul li {
    list-style: none;
    display: inline-block;
    margin-right: 45px;
}

.copyright ul li.active a {
    color: chocolate;
}

.copyright ul li.active a:after {
    opacity: 1;
}

.copyright ul li:last-child {
    margin-right: 0;
}

.copyright ul li a {
    color: #a1a1a1;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
    display: inline-block;
    padding: 5px;
    position: relative;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.copyright ul li a:hover {
    color: #F9AD81;
}

.copyright ul li a:hover::after {
    opacity: 1;
}

.copyright ul li a:after {
    position: absolute;
    left: 50%;
    bottom: -5px;
    width: 5px;
    height: 5px;
    background: #F9AD81;
    content: "";
    border-radius: 50%;
    -webkit-transform: translateX(-2.5px);
    transform: translateX(-2.5px);
    opacity: 0;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}