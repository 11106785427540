
  
  div.elem-group {
    margin: -2px 0;
  }
  div.elem-grou {
    margin-top: -2px 0;
  }
  
  div.elem-group.inlined {
    width: 49%;
    display: inline-block;
    float: left;
    margin-left: 1%;
  }
  
  .lablee {
    display: block;
    font-family: 'Nanum Gothic';
    padding-bottom: 1px  !important;
    font-size: 1.25em;
  }
  
  input, select, textarea {
    border-radius: 2px;
    border: 2px solid #777;
    box-sizing: border-box;
    font-size: 1.25em;
    font-family: 'Nanum Gothic';
    width: 100%;
    padding: 10px;
  }
  
  div.elem-group.inlined input {
    width: 95%;
    display: inline-block;
  }
  
  textarea {
    height: 250px;
  }
  
  hr {
    border: 1px dotted #ccc;
  }
  
  .bookbutton {
    height: 50px;
    width: 170px;
    background: #6e2b21;
    border: none;
    color: white;
    font-size: 1.25em;
    font-family: 'Nanum Gothic';
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    border: 2px solid black;
  }

  @media(max-width: 425px){
    button {
        height: 41px;
        width: 111px;
        background: orange;
        border: none;
        margin-bottom: 6px;
        color: white;
        font-size: 1.25em;
        font-family: 'Nanum Gothic';
        border-radius: 4px;
        cursor: pointer;
    }
  }