/* .navbar {
    background-color: transparent !important; 
    box-shadow: none;
    padding-left: 30px;
    padding-right: 30px;
}

.navbar .nav-link {
    color: black;
}

.navbar .navbar-brand img {
    max-height: 60px; 
}


.navbar .nav-link:hover {
    color: #6e2b21; 
}


.navbar-toggler {
    border-color: rgba(255, 255, 255, 0.5); 
}

.navbar-toggler-icon {
    background-color: rgba(255, 255, 255, 0.5); 
}


.bookbutton{
    background: #6e2b21;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-family: Nanum Gothic;
    font-size: 1.25em;
    height: 50px;
    width: 170px;
}

.navbar-nav{
    justify-content: center;
    display: flex;
    align-items: center;
    gap:30px;
}

.navbar-nav .nav-link {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: .5px;
    position: relative; 

} */


/*---------------------
  Header
-----------------------*/

.header-section {
    /* background: #353535; */
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 999;
  }
  
  .logo1 {
    float: left;
    line-height: 60px;
    width: 70px;
    height: 50px;
  }
  .logo12 {
    /* display: none; */
    float: left;
    line-height: 60px;
    width: 70px;
    height: 50px;
  }
  .logo1 a {
    display: inline-block;
  }
  
  .logo1 a img {
    display: inline-block;
    width: 100%;
    height: 100%;
    transform: scale(1.2, 1.2);
  }
  
  .inner-header .nav-right {
    float: right;
  }
  
  .inner-header .main-menu {
    float: right;
    margin-right: 30px;
    display: block;
  }
  
  .inner-header .main-menu ul li {
    list-style: none;
    display: inline-block;
    margin-right: 50px;
    line-height: 50px;
    padding: 5px;
    position: relative;
    z-index: 1;
  }
  
  .inner-header .main-menu ul li:hover > .drop-menu {
    top: 60px;
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  
  .inner-header .main-menu ul li .drop-menu {
    position: absolute;
    left: 0;
    top: 100px;
    background: #ffffff;
    width: 220px;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  
  .inner-header .main-menu ul li .drop-menu li {
    display: block;
    margin-right: 0;
    line-height: 0;
    padding: 0;
  }
  
  .inner-header .main-menu ul li .drop-menu li a {
    color: #242424;
    display: block;
    padding: 30px 20px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
  }
  
  .inner-header .main-menu ul li .drop-menu li:hover > a {
    background: #f9ad81;
    color: #ffffff;
  }
  
  .inner-header .main-menu ul li .drop-menu li:hover > a::after {
    display: none;
  }
  
  .inner-header .main-menu ul li.active a {
    color: #f9ad81;
  }
  
  .inner-header .main-menu ul li.active a:after {
    opacity: 1;
  }
  
  .inner-header .main-menu ul li:last-child {
    margin-right: 0;
  }
  
  .inner-header .main-menu ul li > a {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    display: inline-block;
    position: relative;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  
  .inner-header .main-menu ul li > a:hover {
    color: #f9ad81;
  }
  
  .inner-header .main-menu ul li > a:hover::after {
    opacity: 1;
  }
  
  .inner-header .main-menu ul li > a:after {
    position: absolute;
    left: 50%;
    bottom: 9px;
    width: 5px;
    height: 5px;
    background: #f9ad81;
    content: "";
    border-radius: 50%;
    -webkit-transform: translateX(-2.5px);
    transform: translateX(-2.5px);
    opacity: 0;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  
  .slicknav_menu {
    display: block;
  }
  
  .logo {
    /* float: left; */
    position: absolute;
    top: 30%;
    /* line-height: 60px; */
    width: 90px;
    height: 90px;
  }
  
  .logo a img {
    width: 100%;
    height: 100%;
    transform: scale(2, 2);
  }
  
  @media (max-width: 1440px) {
    .logo {
      /* float: left; */
      position: absolute;
      top: 20%;
      width: 90px;
      height: 90px;
    }
  
    .logo a img {
      width: 100%;
      height: 100%;
      transform: scale(2, 2);
    }
  }
  
  @media (max-width: 990px) {
    .logo {
      /* float: left; */
      position: absolute;
      top: 10%;
      width: 90px;
      height: 90px;
    }
  
    .logo a img {
      width: 100%;
      height: 100%;
      transform: scale(2, 2);
    }
  }
  
  @media (max-width: 1250px) {
    .logo {
      /* float: left; */
      position: absolute;
      /* top:-120px;
          left:50%; */
      /* line-height: 60px; */
      width: 90px;
      height: 90px;
    }
  
    .logo a img {
      width: 100%;
      height: 100%;
      transform: scale(2, 2);
    }
  }
  
  @media (max-width: 500px) {
    .logo {
      /* float: left; */
      position: absolute;
  
      /* line-height: 60px; */
      width: 90px;
      height: 90px;
    }
  
    .logo a img {
      width: 100%;
      height: 100%;
      transform: scale(2, 2);
    }
  }
  
  @media (max-width: 471px) {
    .logo {
      /* float: left; */
      position: absolute;
      top: 10%;
  
      /* line-height: 60px; */
      width: 90px;
      height: 90px;
    }
  
    .logo a img {
      width: 100%;
      height: 100%;
      transform: scale(2, 2);
    }
  }
  
  .inner-header .nav-right {
    float: right;
  }
  
  .inner-header .main-menu {
    float: right;
    margin-right: 30px;
    display: block;
  }
  
  .inner-header .main-menu ul li {
    list-style: none;
    display: inline-block;
    margin-right: 50px;
    line-height: 50px;
    padding: 5px;
    position: relative;
    z-index: 1;
  }
  
  .inner-header .main-menu ul li:hover > .drop-menu {
    top: 60px;
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  
  .inner-header .main-menu ul li .drop-menu {
    position: absolute;
    left: 0;
    top: 100px;
    background: #ffffff;
    width: 220px;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  
  .inner-header .main-menu ul li .drop-menu li {
    display: block;
    margin-right: 0;
    line-height: 0;
    padding: 0;
  }
  
  .inner-header .main-menu ul li .drop-menu li a {
    color: #242424;
    display: block;
    padding: 30px 20px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
  }
  
  .inner-header .main-menu ul li .drop-menu li:hover > a {
    background: #cd5f2d;
    color: #ffffff;
  }
  
  .inner-header .main-menu ul li .drop-menu li:hover > a::after {
    display: none;
  }
  
  .inner-header .main-menu ul li.active a {
    color: #cd5f2d;
  }
  
  .inner-header .main-menu ul li.active a:after {
    opacity: 1;
  }
  
  .inner-header .main-menu ul li:last-child {
    margin-right: 0;
  }
  
  .inner-header .main-menu ul li > a {
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.5px;
    display: inline-block;
    position: relative;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  
  .inner-header .main-menu ul li > a:hover {
    color: #cd5f2d;
  }
  
  .inner-header .main-menu ul li > a:hover::after {
    opacity: 1;
  }
  
  .inner-header .main-menu ul li > a:after {
    position: absolute;
    left: 50%;
    bottom: 9px;
    width: 5px;
    height: 5px;
    background: #f9ad81;
    content: "";
    border-radius: 50%;
    -webkit-transform: translateX(-2.5px);
    transform: translateX(-2.5px);
    opacity: 0;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  
  .slicknav_menu {
    display: none;
  }
  
  /* Large Mobile */
  
  /* ----------------------------- Responsive Media Styles -----------------------------*/
  
  @media only screen and (min-width: 1500px) and (max-width: 1920px) {
    .inner-header .main-menu {
      margin-right: 96px;
    }
  
    .inner-header .main-menu ul li {
      margin-right: 69px;
    }
  
    .ri-text {
      padding-left: 145px;
      padding-right: 180px;
      padding-bottom: 51px;
    }
  
    .ri-text.left-side {
      padding-left: 185px;
      padding-right: 100px;
    }
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1300px) {
    .inner-header .main-menu ul li {
      margin-right: 30px;
    }
  }
  
  /* Medium Device */
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .header-section {
      padding-left: 0;
      padding-right: 0;
    }
  
    .facilities-item {
      padding-left: 50px;
      padding-right: 15px;
    }
  
    .inner-header .main-menu {
      margin-right: 10px;
    }
  
    .inner-header .main-menu ul li {
      margin-right: 12px;
    }
  
    .check-form {
      margin: 0;
    }
  
    .check-form .datepicker {
      width: calc(17.3% - 19px);
      margin-right: 10px;
    }
  
    .check-form .room-quantity {
      width: calc(36% - 16px);
      margin-right: 10px;
    }
  
    .check-form .room-quantity .single-quantity {
      margin-right: 15px;
    }
  
    .check-form .room-selector {
      width: calc(25% - 16px);
      margin-right: 10px;
    }
  
    .check-form .room-selector .nice-select .list {
      width: auto;
    }
  
    .check-form button {
      padding: 43px 19px;
    }
  
    .check-form .datepicker img {
      display: none;
    }
  
    .mc-item .mc-num {
      float: none;
      overflow: hidden;
    }
  
    .mc-item .mc-text {
      float: none;
    }
  
    .ri-text {
      padding-top: 105px;
      padding-left: 45px;
    }
  
    .ri-text.left-side {
      padding-top: 55px;
      padding-left: 45px;
    }
  
    .kid-text {
      padding-top: 25px;
      padding-bottom: 12px;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .search-filter {
      position: relative;
      margin-top: -230px;
    }
  
    .check-form {
      margin: 0;
    }
  
    .check-form .datepicker {
      width: 100%;
      margin-right: 0;
    }
  
    .check-form .datepicker {
      width: 100%;
      margin-right: 0;
    }
  
    .check-form .room-quantity {
      width: 100%;
      margin-right: 0;
    }
  
    .check-form .room-quantity .single-quantity {
      margin-right: 80px;
    }
  
    .check-form .room-selector {
      width: 100%;
      margin-right: 0;
    }
  
    .check-form button {
      width: 100%;
      padding: 15px 27px;
    }
  
    .mc-item .mc-num {
      float: none;
      overflow: hidden;
    }
  
    .mc-item .mc-text {
      float: none;
    }
  }
  
  .slicknav_btn,
  .slicknav_nav .slicknav_item {
    cursor: pointer;
  }
  
  .slicknav_menu,
  .slicknav_menu * {
    box-sizing: border-box;
  }
  
  .slicknav_btn,
  .slicknav_nav .slicknav_item {
    cursor: pointer;
  }
  
  .slicknav_menu,
  .slicknav_menu * {
    box-sizing: border-box;
  }
  
  .slicknav_btn {
    position: relative;
    display: block;
    float: right;
    padding: 0.438em 0.625em;
    line-height: 1.125em;
  }
  
  .slicknav_btn .slicknav_icon-bar {
    margin-top: 0.188em;
  }
  
  .slicknav_menu .slicknav_menutxt {
    display: block;
    line-height: 1.188em;
    float: left;
    color: #fff;
    font-weight: 700;
    text-shadow: 0 1px 3px #000;
  }
  
  .slicknav_menu .slicknav_icon {
    float: left;
    width: 1.125em;
    height: 0.875em;
    margin: 0.188em 0 0 0.438em;
  }
  
  .slicknav_menu .slicknav_icon:before {
    background: 0 0;
    width: 1.125em;
    height: 0.875em;
    display: block;
    content: "";
    position: absolute;
  }
  
  .slicknav_menu .slicknav_no-text {
    margin: 0;
  }
  
  .slicknav_menu .slicknav_icon-bar {
    display: block;
    width: 1.125em;
    height: 0.125em;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  }
  
  .slicknav_menu:after,
  .slicknav_menu:before {
    content: " ";
    display: table;
  }
  
  .slicknav_menu:after {
    clear: both;
  }
  
  .slicknav_nav li,
  .slicknav_nav ul {
    display: block;
  }
  
  .slicknav_nav .slicknav_arrow {
    font-size: 0.8em;
    margin: 0 0 0 0.4em;
  }
  
  .slicknav_nav .slicknav_item a {
    display: inline;
  }
  
  .slicknav_nav .slicknav_row,
  .slicknav_nav a {
    display: block;
  }
  
  .slicknav_nav .slicknav_parent-link a {
    display: inline;
  }
  
  .slicknav_menu {
    *zoom: 1;
    font-size: 16px;
    background: #4c4c4c;
    padding: 5px;
  }
  
  .slicknav_nav,
  .slicknav_nav ul {
    list-style: none;
    overflow: hidden;
    padding: 0;
  }
  
  .slicknav_menu .slicknav_icon-bar {
    background-color: #fff;
  }
  
  .slicknav_btn {
    margin: 5px 5px 6px;
    text-decoration: none;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #222;
  }
  
  .slicknav_nav {
    clear: both;
    color: #fff;
    margin: 0;
    font-size: 0.875em;
  }
  
  .slicknav_nav ul {
    margin: 0 0 0 20px;
  }
  
  .slicknav_nav .slicknav_row,
  .slicknav_nav a {
    padding: 5px 10px;
    margin: 2px 5px;
  }
  
  .slicknav_nav .slicknav_row:hover {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    background: #ccc;
    color: #fff;
  }
  
  .slicknav_nav a {
    text-decoration: none;
    color: #fff;
  }
  
  .slicknav_nav a:hover {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    background: #ccc;
    color: #222;
  }
  
  .slicknav_nav .slicknav_txtnode {
    margin-left: 15px;
  }
  
  .slicknav_nav .slicknav_item a,
  .slicknav_nav .slicknav_parent-link a {
    padding: 0;
    margin: 0;
  }
  
  .slicknav_brand {
    float: left;
    color: #fff;
    font-size: 18px;
    line-height: 30px;
    padding: 7px 12px;
    height: 44px;
  }
  
  /* Tablet Device */
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-section {
      padding-left: 0;
      padding-right: 0;
    }
  
    .inner-header .main-menu ul li {
      margin-right: 15px;
    }
  
    .inner-header .main-menu {
      margin-right: 0;
    }
  
    .inner-header .nav-right {
      display: none;
      border-radius: 30px;
    }
  
    .intro-right {
      padding-top: 40px;
    }
  
    /* .about-us .about-img.second-img {
          padding-top: 184px;
      } */
    .mc-item .mc-num {
      float: none;
      overflow: hidden;
    }
  
    .mc-item .mc-text {
      float: none;
    }
  
    .callto-section .callto-text {
      float: none;
      margin-bottom: 20px;
    }
  
    .callto-section .primary-btn {
      float: none;
    }
  
    .copyright ul li {
      margin-right: 10px;
    }
  
    .room-section {
      padding-top: 100px;
    }
  
    .ri-slider-item .ri-sliders .single-img {
      height: 750px;
    }
  
    .ri-text {
      padding-top: 100px;
    }
  
    .ri-text.left-side {
      padding-top: 100px;
    }
  
    .blog-right {
      padding-top: 50px;
    }
  
    .contact-title .primary-btn {
      float: none;
      margin-top: 0;
    }
  
    .contact-form {
      margin-bottom: 40px;
    }
  }
  
  /* Large Mobile */
  
  @media only screen and (max-width: 767px) {
    .header-section {
      padding-left: 0;
      padding-right: 0;
    }
  
    .inner-header .main-menu {
      display: none;
    }
  
    .inner-header .nav-right {
      display: none;
    }
  
    .slicknav_menu {
      display: block;
      background: transparent;
    }
  
    .slicknav_btn {
      margin-top: 7px;
      background-color: transparent;
    }
  
    .slicknav_menu .slicknav_menutxt {
      display: none;
    }
  
    .slicknav_menu .slicknav_icon-bar {
      width: 30px;
      height: 5px;
    }
  
    .slicknav_nav {
      background: #222;
      padding: 15px 0;
    }
  
    .slicknav_nav li:hover {
      border-radius: 0;
      background: #f9ad81;
      color: #ffffff;
    }
  
    .slicknav_nav li li:hover {
      background: #222;
      color: #ffffff;
    }
  
    .slicknav_nav li:hover a {
      color: #ffffff;
    }
  
    .slicknav_nav a {
      padding: 0;
      margin: 0;
    }
  
    .slicknav_nav .slicknav_row:hover {
      border-radius: 0;
      background: transparent;
    }
  
    .slicknav_nav .slicknav_row,
    .slicknav_nav a {
      padding: 0;
      margin: 0;
    }
  
    .slicknav_nav a:hover {
      border-radius: 0;
      background: transparent;
    }
  
    .slicknav_nav ul {
      margin: 0;
    }
  
    .slicknav_nav li {
      padding: 15px 35px;
      margin: 0;
    }
  
    .hero-area {
      height: 665px;
      padding-top: 280px;
    }
  
    /* .hero-text h1 {
          font-size: 70px;
      } */
    .search-filter {
      margin-top: -140px;
    }
  
    .check-form {
      padding-right: 30px;
      padding-left: 30px;
      padding-bottom: 40px;
    }
  
    .check-form .room-quantity .single-quantity {
      margin-right: 30px;
    }
  
    .intro-right {
      padding-top: 40px;
    }
  
    .ri-slider-item .ri-sliders .single-img {
      height: 550px;
    }
  
    .about-us .about-img.second-img,
    .about-us .about-img.third-img {
      padding-top: 0;
    }
  
    .facilities-item {
      padding-left: 40px;
      padding-right: 15px;
    }
  
    .facilities-item .fi-title {
      margin-bottom: 330px;
    }
  
    .copyright ul li {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  
    .ri-slider-item .ri-sliders .single-img {
      height: 450px;
    }
  
    .callto-section .callto-text {
      float: none;
      margin-bottom: 20px;
    }
  
    .callto-section .primary-btn {
      float: none;
    }
  
    .copyright .copyright-text {
      float: none;
      margin-bottom: 10px;
    }
  
    .copyright ul {
      float: none;
    }
  
    .room-section {
      padding-top: 100px;
    }
  
    .ri-text .ri-features .ri-info {
      margin-right: 20px;
    }
  
    .ri-text {
      padding-top: 100px;
      padding-left: 30px;
    }
  
    .ri-text.left-side {
      padding-top: 100px;
    }
  
    .blog-right {
      padding-top: 50px;
    }
  
    .contact-title .primary-btn {
      float: none;
      margin-top: 0;
    }
  
    .contact-form {
      margin-bottom: 40px;
    }
  
    .copyright {
      text-align: center;
    }
  }
  
  /* Small Mobile */
  
  @media only screen and (max-width: 479px) {
    .hero-text h1 {
      font-size: 50px;
    }
  
    .intro-text {
      padding: 20px 0;
    }
  
    .check-form .room-quantity .single-quantity {
      margin-right: 4px;
      margin-bottom: 10px;
    }
  
    .section-title h2 {
      font-size: 36px;
    }
  
    .testimonial-section .section-title h2 {
      font-size: 36px;
    }
  
    .facilities-item .fi-title {
      margin-bottom: 180px;
    }
  
    .facilities-item {
      padding-left: 15px;
      padding-right: 15px;
    }
  
    .facilities-item.fi-right .fi-title {
      margin-bottom: 430px;
    }
  
    .ri-text .ri-features .ri-info {
      margin-bottom: 20px;
    }
  }
  
  /* buttons */
  
  .primary-btn {
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    height: 55px;
    padding: 10px;
    margin-top: 10px;
    color: #ffffff;
    border-radius: 6px;
    background: #cd5f2d;
    letter-spacing: 0.5px;
  }
  
  .submit-btn {
    color: #ffffff;
    background: #82d026;
    font-size: 14px;
    font-weight: 400;
    border: none;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  .nav {
    height: 50px;
    width: 100%;
    /* background-color: #4d4d4d; */
    position: relative;
  }
  
  .nav > .nav-header {
    display: inline;
  }
  
  /* .nav-header .logo {
      display: inline;
      height:50px;
      width:50px;
  
    }
   .nav-header .logo img {
      display: inline;
      height:100%;
      width:100%;
      
    } */
  .nav > .nav-header {
    display: inline-block;
    font-size: 22px;
    color: #fff;
    padding: 10px 10px 10px 10px;
  }
  
  .nav > .nav-btn {
    display: none;
  }
  
  .nav > .nav-links {
    display: inline;
    float: right;
    font-size: 18px;
  }
  
  .nav > .nav-links > a {
    display: inline-block;
    padding: 13px 10px 13px 10px;
    text-decoration: none;
    color: #efefef;
  }
  
  .nav > .nav-links > a:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .nav > #nav-check {
    display: none;
  }
  
  @media (max-width: 600px) {
    .nav > .nav-btn {
      display: inline-block;
      position: absolute;
      right: 0px;
      top: 0px;
    }
  
    .nav > .nav-btn > label {
      display: inline-block;
      width: 50px;
      height: 50px;
      padding: 13px;
    }
  
    .nav > .nav-btn > label:hover,
    .nav #nav-check:checked ~ .nav-btn > label {
      background-color: rgba(0, 0, 0, 0.3);
    }
  
    .nav > .nav-btn > label > span {
      display: block;
      width: 25px;
      height: 10px;
      border-top: 2px solid #eee;
    }
  
    .nav > .nav-links {
      position: absolute;
      display: block;
      width: 100%;
      background-color: #333;
      height: 0px;
      transition: all 0.3s ease-in;
      overflow-y: hidden;
      top: 50px;
      left: 0px;
    }
  
    .nav > .nav-links > a {
      display: block;
      width: 100%;
    }
  
    .nav > #nav-check:not(:checked) ~ .nav-links {
      height: 0px;
    }
  
    .nav > #nav-check:checked ~ .nav-links {
      height: 300px;
      overflow-y: auto;
      width: 50%;
      left: 50%;
    }
  }
  
  
  .header-section {
      display: flex;
      justify-content: center; /* Center the header contents */
      align-items: center; /* Vertically center items */
  }
  
  .inner-header {
      display: flex;
      justify-content: space-between; /* Space between logo and nav */
      align-items: center; /* Vertically center items */
      flex-wrap: wrap; /* Allow items to wrap on smaller screens */
      width: 100%;
      padding: 10px; /* Add some padding */
  }
  
  .logo1 {
      line-height: 60px;
      width: auto;
      max-width: 100%; /* Ensure it doesn't exceed its container */
      height: auto;
  }
  
  .logo1 a img {
      display: inline-block;
      max-width: 100%; /* Make logo responsive */
      height: auto; /* Maintain aspect ratio */
  }
  
  .main-menu ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
  }
  
  .main-menu ul li {
      margin-left: 20px; /* Space between menu items */
  }
  
  .nav {
      display: none; /* Hide mobile nav by default */
  }
  
  .nav-btn {
      cursor: pointer;
  }
  
  .nav-links {
      display: flex;
      flex-direction: column; /* Stack links on mobile */
  }
  
  @media (max-width: 768px) {
      .nav {
          display: block; /* Show mobile nav on smaller screens */
      }
  
      .nav-links {
          display: none; /* Hide links by default */
          position: absolute; /* Positioning for dropdown */
          top: 60px; /* Adjust as needed */
          background-color: white; /* Background for dropdown */
          width: 100%; /* Full width */
          z-index: 10; /* Ensure it appears on top */
      }
  
      #nav-check:checked ~ .nav-links {
          display: flex; /* Show links when checkbox is checked */
      }
  
      .logo1 {
          line-height: 40px; /* Adjust for smaller screens */
          width: 50px; /* Smaller logo for mobile */
      }
  }
  


.navbar {
  position: absolute;
  background-color: transparent !important;
  background: none;
  box-shadow: none;
  padding-left: 30px;
  padding-right: 30px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.navbar .nav-link {
    color: white;
}

.navbar .navbar-brand img {
    max-height: 60px; 
}


.navbar .nav-link:hover {
    color: #cd5f2d; 
}


.navbar-toggler {
    border-color: rgba(255, 255, 255, 0.5); 
}

.navbar-toggler-icon {
    background-color: rgba(255, 255, 255, 0.5); 
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: #cd5f2d ;
}
.bookbutton{
    background: #6e2b21;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-family: Nanum Gothic;
    font-size: 1.25em;
    height: 50px;
    width: 170px;
}

.navbar-nav{
    justify-content: center;
    display: flex;
    align-items: center;
    gap:30px;
}

.navbar-nav .nav-link {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: .5px;
    position: relative; 

} 

@media (max-width: 768px){
  .navbar-nav {
    align-items: end;
    padding-right: 50px;
    background-color: rgba(0, 0, 0, 0.3);
    padding:10px;
  }
}